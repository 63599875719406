const SPORTS_WITHOUT_MEETING_URL = [1000];

export default () => ({
  handler: 'relativeUrl',
  data: ({
    sportId, meetingId, eventId, sportCouponGroupId,
  }) => {
    const eventUrl = SPORTS_WITHOUT_MEETING_URL.includes(parseInt(sportId, 10))
      ? `/sports/${sportId}/events/${eventId}`
      : `/sports/${sportId}/meetings/${meetingId}/events/${eventId}`;

    return sportCouponGroupId ? `${eventUrl}?market_group=${sportCouponGroupId}` : eventUrl;
  },
});
