import { getCookie, setCookie } from 'bv';

const MAX_RECENT_SEARCHES = 5;

const cookieKey = (searchType) => `recent_searches_${searchType.toLowerCase()}`;

export const getRecentSearches = (searchType) => {
  try { return JSON.parse(getCookie(cookieKey(searchType)) || '[]'); } catch { return []; }
};

const setRecentSearches = (searchType, recentSearches) => setCookie(cookieKey(searchType),
  JSON.stringify(recentSearches));

export const addRecentSearch = (searchType, searchTerm) => setRecentSearches(searchType,
  [
    searchTerm,
    ...(getRecentSearches(searchType, searchTerm).filter((result) => result !== searchTerm)),
  ].slice(0, MAX_RECENT_SEARCHES));

export const removeRecentSearch = (searchType, searchTerm) => setRecentSearches(searchType,
  getRecentSearches(searchType, searchTerm)
    .filter((result) => result !== searchTerm));
