// Some multiples have "generated" fractions like 1.234/1 need to allow for . in the numerator
export const fractionRegex = /^[0-9.]+\/\d+$/;

export const isFraction = (price) => fractionRegex.test(price);

export const toDecimal = (price) => {
  if (isFraction(price)) {
    const parts = price.split('/').map((p) => parseFloat(p, 10));
    return (parts[0] / parts[1]);
  }

  return price;
};

export const toThreeDecimals = (number) => Math.round(number * 1000) / 1000;
