import { v as bvVar } from 'bv';
import { locale } from '../../i18n';
import { handleDepartmentStatusChange } from '../service/routing';

export const preConfigurations = () => {
  const { brandName } = bvVar('zendeskConfig');
  const { deviceType } = bvVar('deviceInfo');
  const tags = [deviceType, brandName];

  window.zESettings = {
    webWidget: {
      chat: {
        tags,
        departments: {
          enabled: [],
        },
      },
      contactForm: {
        tags,
      },
    },
  };
};

export const afterConfigurations = () => (
  new Promise((resolve) => {
    window.zE('webWidget:on', 'chat:connected', () => {
      window.zE('webWidget', 'setLocale', locale());
      window.zE('webWidget', 'hide');
      window.zE('webWidget:on', 'close', () => {
        if (!window.zE('webWidget:get', 'chat:isChatting')) window.zE('webWidget', 'hide');
      });
      window.zE('webWidget:on', 'chat:departmentStatus', (department) => {
        handleDepartmentStatusChange(department);
      });
      resolve();
    });

    /* Will force the widget to display if the chat is started proactively by an agent */
    window.zE('webWidget:on', 'chat:start', () => {
      window.zE('webWidget', 'show');
    });
  })
);

export const loggedInConfigurations = (accountData) => {
  const csLevel = `CS_LEVEL: ${accountData.customer_service_level}`;
  const accountNumber = `Account_Number: ${accountData.account_number}`;
  const countryCode = `CountryCode: ${accountData.country_code}`;
  const { brandName } = bvVar('zendeskConfig');
  const { deviceType } = bvVar('deviceInfo');

  const tags = [deviceType, brandName, accountNumber, csLevel, countryCode];
  const { zE, zESettings } = window;
  zESettings.webWidget.chat.tags = tags;
  zESettings.webWidget.contactForm.tags = tags;

  zE('webWidget', 'updateSettings', zESettings.webWidget);
};
