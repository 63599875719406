import { setFragment, setLocation, openNewTab } from '../../../helpers/location';

export {
  setFragment as relativeUrl,
  setLocation as absoluteUrl,
  openNewTab,
};
export { liveChat } from '../../zendesk';
export { default as showRightSidebarComponent } from './right_sidebar';
export { default as openMenu } from './open_menu';

export const externalUrl = ({ openInNewTab, url }) => (
  (openInNewTab === '1' ? openNewTab : setLocation)(url)
);
