const refreshBalanceMessage = (balanceData) => {
  const messageObject = {
    eventAction: 'refreshBalance',
    refreshedBalance: balanceData,
  };

  window.top.postMessage(messageObject, '*');
};

export default refreshBalanceMessage;
