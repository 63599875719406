/* global hcaptcha */
import { v as bvVar } from 'bv';
import BaseHCaptchaService from './base_hcaptcha_service';

const { siteKey } = bvVar('captchaSettings');

export default class InvisibleHCaptchaCaptchaService extends BaseHCaptchaService {
  resolve = null;

  refresh = () => {
    this.widgetId = hcaptcha.render('hcaptcha', {
      sitekey: siteKey,
      size: 'invisible',
      callback: this.callback,
      'close-callback': this.closeCallback,
    });
  };

  execute = () => new Promise((resolve) => {
    if (this.widgetId) {
      hcaptcha.reset(this.widgetId);
    }
    this.resolve = resolve;
    hcaptcha.execute(this.widgetId);
  });

  callback = (token) => {
    this.resolve(token);
  };

  closeCallback = () => {
    this.resolve();
  };

  destroy = () => {
    hcaptcha.reset(this.widgetId);
    this.widgetId = '';
    this.resolve = null;
  };
}
