import {
  DAY_IN_MILLIS,
  HOUR_IN_MILLIS,
  MINUTE_IN_MILLIS,
  SECOND_IN_MILLIS,
} from './constants';

export default (startTime, options = {}) => {
  const { hoursAsMintues, daysAsHours } = options;

  const distance = new Date(startTime) - Date.now();
  const absoluteDistance = Math.abs(distance);

  let hours;
  let minutes;

  if (hoursAsMintues) {
    hours = 0;
    minutes = Math.floor(absoluteDistance / MINUTE_IN_MILLIS);
  } else {
    if (daysAsHours) {
      hours = Math.floor(absoluteDistance / HOUR_IN_MILLIS);
    } else {
      hours = Math.floor((absoluteDistance % DAY_IN_MILLIS) / HOUR_IN_MILLIS);
    }
    minutes = Math.floor((absoluteDistance % HOUR_IN_MILLIS) / MINUTE_IN_MILLIS);
  }

  const seconds = Math.floor((absoluteDistance % MINUTE_IN_MILLIS) / SECOND_IN_MILLIS);

  return {
    distance,
    hours,
    minutes,
    seconds,
  };
};
