import { compose } from 'underscore';
import { v as bvVar } from 'bv';
import { locale } from '../services/i18n';

const withLocaleRegex = RegExp(`^/?${locale()}/`, 'i');
const rootRegex = RegExp(`^/?(${locale()})?/?$`);

const isLocalised = RegExp.prototype.test.bind(withLocaleRegex);
const isRoot = RegExp.prototype.test.bind(rootRegex);
const addLocaleTo = String.prototype.concat.bind(`/${locale()}`);

const withLocale = (link) => (isLocalised(link) ? link : addLocaleTo(link));

// Links to root (/ or /en-gb) should redirect to the proper root path
// Otherwise, link with locale
const linkLocalizer = (link) => (isRoot(link) ? bvVar('rootPath') : withLocale(link));

// Given a function that returns a path, I.e. /casino/games/1
// returns a function that will return locale + path, I.e. /en-gb/casino/games/1
const localizeFn = (fn) => compose(linkLocalizer, fn);

export {
  isRoot,
  linkLocalizer,
  localizeFn,
};
