export default (accountData) => {
  const name = `${accountData.first_name} ${accountData.last_name}`;
  window.zE('webWidget', 'identify', {
    name,
    email: accountData.email,
  });
  window.zE('webWidget', 'prefill', {
    name: {
      value: name,
      readOnly: true,
    },
    email: {
      value: accountData.email,
      readOnly: true,
    },
  });
};
