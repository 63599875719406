import { v as bvVar } from 'bv';

const {
  groupDeposit = false,
  groupWithdraw = false,
} = bvVar('siteConfigs').payment_solution_grouping || {};

export {
  groupDeposit,
  groupWithdraw,
};
