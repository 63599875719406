import { isInComponentsIframe } from '../../embedded';

export default () => ({
  handler: isInComponentsIframe() ? 'openNewTab' : 'relativeUrl',
  data: ({ accountSection }) => ({
    ACCOUNTHISTORYOPENBETS: '/bv_cashier/history/3',
    ACCOUNTHISTORYSETTLEDBETS: '/bv_cashier/history/4',
    ACCOUNTHISTORYPAYMENTS: '/bv_cashier/history/5',
    ACCOUNTHISTORYCASINO: '/bv_cashier/history/1',
    GAMEHISTORY: '/bv_cashier/history',
    DEPOSIT: '/bv_cashier/deposit',
    ACCOUNTDETAILSSETTINGS: '/preferences/edit',
    FORGOTPASSWORD: '/account/password_reset',
    LOGIN: '/login',
    CREATEACCOUNT: '/account/new',
    REFERAFRIEND: '/refer_a_friend',
    SGPORTAL: '/safer_gambling/portal',
    VERIFICATIONZONE: '/account/verification_zone',
  }[accountSection] || '/bv_cashier/overview'),
});
