import appendTrackerParams from './append_tracker_params';
import handleNavigation from './handle_navigation';

const handleClickEvent = (e, type) => {
  const aLink = e.target.closest('a');
  const href = aLink?.getAttribute('href');
  if (!href) return;

  appendTrackerParams(e, href, type);

  if (type === 'frontend') {
    handleNavigation(e, href, aLink);
  }
};

export default (type) => {
  document.addEventListener('click', (e) => handleClickEvent(e, type), false);
};
