import * as configs from './configs';
import * as handlers from './handlers';

export default ({ action, ...rest }) => {
  const config = configs[action];

  if (config) {
    const { handler, data } = config();
    handlers[handler](data(rest));
  }
};
