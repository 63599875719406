// The purpose of this helper is to format a number by:
// - Adding/using the delimiter (if neccessary)
// - Using the proper separator (if the number has decimal part or forced)
// - Decimals forced by bildbet, instead it should be a config (settings.yml): force_precision
// - When forcing, instead of hardcoding to 2 decimals, I18n translation should be used.
// - number.currency.format.precision for example, though it should not be currency specific
// - number.format.precision, currency namespace is wrong as it's not always currencies
// - Rails uses differene translations so format depends on what is formatted
import { isNull, isUndefined } from 'underscore';
import { t } from 'bv-i18n';
import { v as bvVar } from 'bv';

const addDelimiter = (amountString) => (
  amountString.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${t('javascript.currency.format.delimiter')}`)
);

// Once this helper is only called with numbers (it should be like that)
// We should use number.toFixed for german
// Then replace decimal separator by the translated and thousands delimiter
const parseDecimalPart = (decimalPart, precision) => `${decimalPart}00`.substring(0, precision);

export default (number, { withPaddedDecimals } = { withPaddedDecimals: false }) => {
  if (isNull(number) || isUndefined(number)) return null;

  // Remove thousands separator if we had them, gonna be added back later
  // Won't be needed if this helper is only called with actual numbers
  // And split by expected decimal separator
  const [integerPart, decimalPart] = number.toString().replace(',', '').split('.');

  // Bild Brand specific rules: always pad things to 2 decimals
  // TODO: Replace this by a config
  // not done yet as there are many fixes to do for BildBet anyway
  const germanBrand = [12, 18].includes(bvVar('brandId'));

  return [
    addDelimiter(integerPart),
    germanBrand || withPaddedDecimals ? parseDecimalPart(decimalPart || '', 2) : decimalPart,
  ].filter(Boolean).join(t('javascript.currency.format.separator'));
};
