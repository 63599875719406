import { requireScript } from 'bv';
import { guard } from '../features';

const { JSBundles } = window;

let promise;

export default () => {
  promise = promise || Promise.race([
    // 10 second timeout, and if we get no result from iobb continue without it
    new Promise((resolve) => {
      setTimeout(resolve, 10000);
    }),
    guard('iovation')
      .then((enabled) => {
        if (!enabled) return null;

        return requireScript(JSBundles.IovationLoader)
          .then(() => window.IovationLoader.default());
      }).catch(() => null),
  ]);

  return promise;
};
