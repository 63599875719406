/* eslint-disable no-extend-native, func-names */

// Force all Date.toLocale*String methods to use the gregorian calendar
// This is needed because in th-th locale the default is the buddhist calendar, but in practice
// we want to use the usual western dates (most people do in Thailand).
//
// There is no global configuration for this in JS, so instead we are hijacking the Date.propotype.*
// functions and add the calendar: 'gergory' to it.

export default () => {
  const defaultsLocaleTimeParams = {
    'en-en': { hourCycle: 'h23' },
    'en-zn': { hourCycle: 'h23' },
  };
  const RealDateToLocaleString = Date.prototype.toLocaleString;
  const RealDateToLocaleDateString = Date.prototype.toLocaleDateString;
  const RealDateToLocaleTimeString = Date.prototype.toLocaleTimeString;

  const dateFormatOpts = (locale, opts) => ({
    ...opts,
    ...defaultsLocaleTimeParams[locale],
    calendar: 'gregory',
  });

  Date.prototype.toLocaleString = function (locale, opts) {
    return RealDateToLocaleString.call(this, locale, dateFormatOpts(locale, opts));
  };

  Date.prototype.toLocaleDateString = function (locale, opts) {
    return RealDateToLocaleDateString.call(this, locale, dateFormatOpts(locale, opts));
  };

  Date.prototype.toLocaleTimeString = function (locale, opts) {
    return RealDateToLocaleTimeString.call(this, locale, dateFormatOpts(locale, opts));
  };
};
