export const addWithRefCount = (state, records) => {
  if (!records) {
    return state;
  }

  return {
    ...state,
    ...Object.values(records).reduce((acc, record) => {
      const currentRecord = state[record.id];
      const currentRecordCountRef = (currentRecord && currentRecord.refCount) || 0;

      return {
        ...acc,
        [record.id]: {
          ...currentRecord,
          ...record,
          refCount: currentRecordCountRef + 1,
        },
      };
    }, {}),
  };
};

export const removeWithRefCount = (state, ids) => (
  Object.keys(state).reduce((acc, recordId) => {
    const record = state[recordId];

    if (!ids.includes(Number.isNaN(Number(recordId)) ? recordId : parseInt(recordId, 10))) {
      return {
        ...acc,
        [recordId]: record,
      };
    }

    if (record.refCount === 1) return acc;

    return {
      ...acc,
      [recordId]: {
        ...record,
        refCount: record.refCount - 1,
      },
    };
  }, {})
);
