/* global grecaptcha */
import { v as bvVar } from 'bv';
import BaseCaptchaService from './base_captcha_service';

const { jsUrl, siteKey } = bvVar('captchaSettings');

export default class GoogleCaptchaService extends BaseCaptchaService {
  load = () => new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.onload = () => { resolve(); };
    script.onerror = () => { reject(); };
    script.src = `${jsUrl}?render=${siteKey}`;

    document.getElementsByTagName('head')[0].appendChild(script);
  });

  execute = (action) => new Promise((resolve) => {
    grecaptcha.ready(() => (
      grecaptcha.execute(siteKey, { action })
        .then(resolve)
    ));
  });

  destroy = () => {
    const badge = document.querySelector('.grecaptcha-badge');
    if (badge && badge.parentNode) {
      document.body.removeChild(badge.parentNode);
    }

    const script = document.querySelector(`script[src="${jsUrl}?render=${siteKey}"]`);
    if (script) {
      script.remove();
    }
  };
}
