import { noop } from 'underscore';
import { seoEnabled } from 'Seo/helpers';
import { requireFile } from '../../bv/helpers';

export default () => {
  const enabled = seoEnabled();
  const path = window.location.pathname;

  if (!enabled) return;

  requireFile(window.JSBundles.seo).then(() => {
    if (path === window.location.pathname) {
      window.reduxState.store.actionDispatchers.seo.setPrerenderReady();
    }
  }).catch(noop);
};
