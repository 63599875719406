import { throttle } from 'underscore';
import { getJSON } from 'bv-fetch';

import refreshBalanceMessage from 'CashierCommon/helpers/refresh_balance_message';

let getPromise;
let listeners = [];

const fetchBalance = () => getJSON('/account/balance.json');

const get = (opts) => {
  const force = opts && opts.force;

  getPromise = (!force && getPromise) || fetchBalance();

  return getPromise;
};

const notify = (balance) => {
  listeners.forEach((listener) => { listener.call(null, balance); });
};

const refresh = () => get({ force: true }).then((balanceData) => {
  notify(balanceData);

  if (balanceData?.decimalMainBalance) {
    refreshBalanceMessage(balanceData.decimalMainBalance);
  }
});

const set = (accountData) => {
  const balance = {
    mainBalance: accountData.mainBalance,
    decimalMainBalance: accountData.decimalMainBalance,
    bonusBalance: accountData.bonusBalance,
    decimalBonusBalance: accountData.decimalBonusBalance,
  };

  getPromise = Promise.resolve(balance);

  notify(balance);
};

const subscribe = (handler) => {
  listeners.push(handler);
};

const unsubscribe = (handler) => {
  listeners = listeners.filter((listener) => listener !== handler);
};

const throttledRefresh = throttle(refresh, 5000);

export {
  get,
  refresh,
  throttledRefresh,
  set,
  subscribe,
  unsubscribe,
};
