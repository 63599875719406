export default () => ({
  handler: 'relativeUrl',
  data: ({
    sportId, sportTabComponentId, linkGroupSlug, linkSlug,
  }) => {
    let url = `/sports/${sportId}/sections/custom-list/${sportTabComponentId}`;
    if (linkGroupSlug) {
      url += `/group/${linkGroupSlug}`;
      if (linkGroupSlug) {
        url += `/item/${linkSlug}`;
      }
    }
    return url;
  },
});
