import { v as bvVar } from 'bv';
import boot from './boot';
import showServiceManager from './service/service_manager';
import liveChatApp from './live_chat_app';

let bootZendesk;

const reset = () => {
  if (window.zE('webWidget:get', 'chat:isChatting')) {
    window.zE('webWidget', 'reset');
  }
};

const logout = () => {
  if (window.zE) {
    window.zE('webWidget', 'logout');
  }
};

const bootService = () => {
  if (!bootZendesk) bootZendesk = boot();
};

const showService = (service, options = {}) => {
  if (!bootZendesk) bootZendesk = boot();

  bootZendesk.then(() => {
    reset();
    showServiceManager(service, options);
  });
};

const liveChat = (options = {}) => {
  if (bvVar('zendeskConfig').useWebSDK) {
    liveChatApp(options);
  } else {
    showService('chat', options);
    window.dispatchEvent(new Event('zE_show_preloader')); // show preloader chat pop-up
  }
};

export {
  bootService,
  showService,
  liveChat,
  logout,
};
