export default () => ({
  handler: 'relativeUrl',
  data: ({ promoHubSection }) => `/offer${
    {
      SPORTSOFFERS: '/sports',
      CASINOOFFERS: '/casino',
      BONUSBALANCEBREAKDOWN: '/wallets',
    }[promoHubSection] || ''
  }`,
});
