import { v as bvVar } from 'bv';

const promotionRedirectUrls = bvVar('promotionRedirectUrls');

export default () => ({
  handler: 'absoluteUrl',
  data: ({ pointTo }) => {
    switch (pointTo) {
      case 'ios_app':
        return promotionRedirectUrls?.iosAppUrl;
      case 'android_app':
        return promotionRedirectUrls?.androidAppUrl;
      case 'bv_casino_ios_app':
        return promotionRedirectUrls?.casinoIosAppUrl;
      case 'oddschecker_app':
        return promotionRedirectUrls?.oddscheckerAppUrl;
      case 'apps_landing_page_for_desktop':
        return promotionRedirectUrls?.desktopLandingAppsUrl;
      default:
        return '';
    }
  },
});
