import { locale } from '../i18n';

const isInComponentsIframe = () => window.location.pathname.startsWith(`/${locale()}/components`);

const embeddedPath = (path) => `${isInComponentsIframe() ? '/components' : ''}${path}`;

export {
  isInComponentsIframe,
  embeddedPath,
};
