const preEventPath = (opts) => (
  opts.sportEventPathId === 1000
    ? `/sports/${opts.sportEventPathId}/events/${opts.eventId}`
    : `/sports/${opts.sportEventPathId}/meetings/${opts.meetingId}/events/${opts.eventId}`
);

const sportPath = (eventPathId) => `/sports/${eventPathId}`;

const meetingPath = (opts) => `/sports/${opts.sportEventPathId}/meetings/${opts.meetingId}/all`;

const customListComponentPath = (opts) => (
  [
    '/sports',
    opts.sportEventPathId,
    'sections/custom-list',
    opts.customListComponentInfo.customListComponentId,
    'group',
    opts.customListComponentInfo.customListComponentLinkGroupIndex,
    'item',
    opts.customListComponentInfo.customListComponentLinkGroupItemIndex,
  ].join('/')
);

// /en-gb/sports/240/sections/custom-list/4/group/0/item/0

export {
  preEventPath,
  sportPath,
  meetingPath,
  customListComponentPath,
};
