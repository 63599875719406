import { getCookie } from 'bv';
import { locale } from 'bv-i18n';

export default (amount, options = {}) => {
  // don't try to format non-numeric things
  if (Number.isNaN(parseFloat(amount))) return amount;

  const currency = getCookie('currency_iso_code');
  const germanLanguageRelated = locale().match(/^(at|de)|(de|at)$/i);
  const formatterOptions = {};

  if (currency) {
    formatterOptions.style = 'currency';
    formatterOptions.currency = currency;
  }
  if (Number.isInteger(options.decimalPlaces)) {
    formatterOptions.minimumFractionDigits = options.decimalPlaces;
    formatterOptions.maximumFractionDigits = options.decimalPlaces;
  } else if (!germanLanguageRelated && parseFloat(amount).toFixed(2).match(/\.00$/)) {
    // remove trailing 0s if not explicity asked for to keep backwards compatibility
    // we used to force them for all german brands, going to keep them if the locale
    // is german language related (has at or de in it somewhere)
    formatterOptions.minimumFractionDigits = 0;
    formatterOptions.maximumFractionDigits = 0;
  }

  try {
    return new Intl.NumberFormat(locale(), formatterOptions).format(amount);
  } catch (e) {
    // if for whatever reason formatting fails
    // e.g.: invalid locale or incorrect formatting options
    // return the original input
    return amount;
  }
};
