const observers = {};

export const subscribe = (topic, observer) => {
  if (!observers[topic]) observers[topic] = [];

  observers[topic].push(observer);
};

export const unsubscribe = (topic, observer) => {
  if (!observers[topic]) return;

  const index = observers[topic].indexOf(observer);

  if (index !== -1) {
    observers[topic].splice(index, 1);
  }
};

export const publish = (topic, ...args) => {
  if (!observers[topic]) return;

  observers[topic].forEach((observer) => {
    observer(...args);
  });
};
