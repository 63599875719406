import { v as bvVar } from 'bv';
import { preConfigurations, afterConfigurations } from './config/base_config';

const loadScript = () => new Promise((resolve) => {
  const { host, apiKey } = bvVar('zendeskConfig');
  const scriptTag = document.createElement('script');

  scriptTag.id = 'ze-snippet';
  scriptTag.src = `${host}?key=${apiKey}`;
  scriptTag.async = 'async';
  scriptTag.defer = 'defer';
  scriptTag.onload = () => resolve();
  document.body.appendChild(scriptTag);
});

export default () => {
  preConfigurations();

  return loadScript()
    .then(afterConfigurations)
    .then(() => window.dispatchEvent(new Event('zE_hide_preloader'))); // remove preloader chat pop-up
};
