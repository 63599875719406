import { seoEnabled } from 'Seo/helpers';
import { noop } from 'underscore';
import { requireFile } from '../../bv/helpers';

export default (getMetaInfoFn) => {
  const enabled = seoEnabled();

  if (!enabled) return;

  const path = window.location.pathname;

  Promise.all([getMetaInfoFn(), requireFile(window.JSBundles.seo)]).then(([metaInfo]) => {
    if (metaInfo && path === window.location.pathname) {
      window.reduxState.store.actionDispatchers.seo.setMetaInfo({
        ...metaInfo,
        path,
      });
    }
  }).catch(noop);
};
