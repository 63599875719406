import { setFragment } from '../../helpers/location';
import { locale } from '../i18n';

export default (e, href, aLink) => {
  if (aLink.getAttribute('data-react')) return;

  const target = aLink.getAttribute('target');
  const isModifiedEvent = !!(e.metaKey || e.altKey || e.ctrlKey || e.shiftKey);

  // As we are catching all the href starting with # due to backwards compatibility,
  // events on links with href="#" will be here even if event.preventDefault()
  // has been used without return false. However, we can consider our action a default action,
  // so it will not be executed if default has been prevented

  if (
    !href.match(/^(https?:\/\/|\/\/|mailto:|tel:)/)
    && (!target || (target && target !== '_blank'))
    && !e.defaultPrevented
    && !isModifiedEvent
  ) {
    e.preventDefault();

    // Remove root
    const path = href.replace(new RegExp(`^/${locale()}`), '');

    // Instruct React router to navigate
    // Using location helper so it adds the leading / if missing
    setFragment(path);
  }
};
