import { accountData } from 'bv';
import { locale } from '../../i18n';

let currentAccountData;

export const getAccountData = () => {
  if (!currentAccountData) {
    currentAccountData = accountData();
  }

  return currentAccountData;
};

export const getAccountRestriction = () => (
  fetch(`/security_checks/${locale()}/account_restriction?name=chat`)
    .then((response) => response.json())
);
