import { createElement } from 'react';
import { createRoot } from 'react-dom/client';
import withLazyLoad from '../../lazy_load';

export default ({ keepHidden = false } = {}) => {
  const liveChatContainer = document.createElement('div');
  liveChatContainer.className = 'zendesk-live-chat-wrapper';
  liveChatContainer.id = 'zendesk-live-chat-container';

  document.getElementById('root').appendChild(liveChatContainer);
  const root = createRoot(document.querySelector('#zendesk-live-chat-container'));

  root.render(
    createElement(withLazyLoad()('zendeskLiveChat'), { keepHidden }),
  );
};
