export default (service) => {
  const settings = {
    helpCenter: { suppress: true },
    contactForm: { suppress: true },
    chat: { suppress: true },
  };

  settings[service].suppress = false;

  return settings;
};
