import { capitalize } from 'underscore.string';
import { t, locale } from '../../services/i18n';
import { DAY_IN_MILLIS } from './constants';

let today = new Date();

const differenceOfDays = (date1, date2) => (date1 - date2) / DAY_IN_MILLIS;

const getMidnight = (day) => {
  const date = new Date(day);
  date.setMilliseconds(999);
  date.setSeconds(59);
  date.setMinutes(59);
  date.setHours(23);
  return date;
};

const isSameDay = (date1, date2) => date1.getDate() === date2.getDate()
  && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();

const isToday = (date) => isSameDay(today, date);

const isTomorrow = (date) => isSameDay(new Date(today.getTime() + DAY_IN_MILLIS), date);

const isThisWeek = (date) => differenceOfDays(getMidnight(date), getMidnight(today)) < 7;

const getHours = (date) => date.toLocaleTimeString(locale(), {
  hour: '2-digit',
  minute: '2-digit',
});

/**
 * is today: Today 14:00
 * is tomorrow: Tomorrow 14:00
 * is within the next 7 days: Friday 14:00
 * after the next 7 days: 8 September at 20:00
 */
export default (dateStr) => {
  const date = new Date(dateStr);
  today = new Date();

  if (isToday(date)) {
    return t('today_with_time', { date: getHours(date) });
  } if (isTomorrow(date)) {
    return t('tomorrow_with_time', { date: getHours(date) });
  } if (isThisWeek(date)) {
    return capitalize(date.toLocaleDateString(locale(), {
      weekday: 'long',
      hour: '2-digit',
      minute: '2-digit',
    }));
  }
  return date.toLocaleDateString(locale(), {
    hour: '2-digit',
    minute: '2-digit',
    day: 'numeric',
    month: 'long',
  });
};
