import { getJSON } from 'bv-fetch';
import stringToHash from 'Helpers/string_to_hash';
import BaseCaptchaService from './base_captcha_service';

const metaTag = document.querySelector("meta[name='captcha-string']");
export const captchaString = metaTag ? metaTag.getAttribute('content') : '';

export default class CustomCaptchaService extends BaseCaptchaService {
  token = stringToHash(captchaString);

  execute = () => Promise.resolve(this.token);

  shouldRetry = () => true;

  refresh = async () => {
    const response = await getJSON('/captcha_string');
    this.token = stringToHash(response.captchaString);
    metaTag.content = response.captchaString;
    return this.token;
  };
}
