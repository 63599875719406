import {
  compose, property, partial, isEqual,
} from 'underscore';
import { v as bvVar } from 'bv';

const getDeviceInfo = () => bvVar('deviceInfo');
const getProperty = (prop) => compose(property(prop), getDeviceInfo);
const isOs = (os) => compose(partial(isEqual, os), getProperty('os'));

export const isIos = isOs('iOS');
export const isAndroid = isOs('Android');
export const version = getProperty('osVersion');
