import { v as bvVar, requireScript } from 'bv';
import BaseCaptchaService from './base_captcha_service';

const { jsUrl } = bvVar('captchaSettings');

export default class BaseHCaptchaService extends BaseCaptchaService {
  widgetId = '';

  load = async () => requireScript(`${jsUrl}?render=explicit`);
}
