import { useEffect, useState } from 'react';
import { seoEnabled } from './seo_enabled';

export default () => {
  const [isSeoEnabled, setIsSeoEnabled] = useState(false);

  useEffect(() => {
    setIsSeoEnabled(seoEnabled());
  }, []);

  return isSeoEnabled;
};
