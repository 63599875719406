import { isInComponentsIframe } from '../../embedded';

export default () => ({
  handler: isInComponentsIframe() ? 'openNewTab' : 'relativeUrl',
  data: ({ productPage }) => ({
    SPORTS: '/sports',
    INPLAY: '/live',
    VIRTUALSPORTS: '/virtuals',
    LOTTERY: '/lottery',
    LIVECASINO: '/live-casino',
  }[productPage] || '/casino'),
});
