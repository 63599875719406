import { isDesktop } from 'bv';

export const calculateScrollPosition = ({ section, title }, navRef) => {
  const header = document.querySelector('.site-header');
  const headerHeight = header?.clientHeight || 0;
  const titleHeight = title.current.clientHeight;
  const navHeight = navRef.current.clientHeight;
  const sectionHeight = section.current.offsetTop;

  const newPosition = sectionHeight - titleHeight - navHeight - headerHeight;

  if (!header?.classList.contains('site-header--scrolling') && isDesktop()) return newPosition + 30; // Account for header changing size
  return newPosition;
};
