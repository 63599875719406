import { mainContextPath, prevMainContextPath, history } from '../services/route';

const setLocation = (location) => {
  window.location.href = location;
  return location;
};

const openNewTab = (location) => window.open(location, '_blank');
const reload = () => window.location.reload();

const setFragment = (fragment) => (
  history.push((`/${fragment}`).replace(/^\/\//, '/'))
);

const replaceFragment = (fragment) => (
  history.replace((`/${fragment}`).replace(/^\/\//, '/'))
);

const getParameter = (name, defaultValue = null) => {
  const value = new URLSearchParams(window.location.search).get(name);

  return value || defaultValue;
};

const modalBack = () => replaceFragment(mainContextPath() || '');
const mainContextBack = () => replaceFragment(prevMainContextPath() || '');

export {
  setLocation,
  openNewTab,
  reload,
  setFragment,
  replaceFragment,
  getParameter,
  modalBack,
  mainContextBack,
};
