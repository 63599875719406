import { getCookie, setCookie, rafToken as getRafToken } from '../../bv';
import { locale } from '../i18n';

// Listen for link click events at the document level
// whenever an appsflyer link is clicked on the page
// Append required parameters to the url
// Change document.location to the new url generated

const cookieParams = ['affid', 'btagid', 'btag'];

const buildDeepLinkValue = (url) => {
  const search = new URLSearchParams(window.location.search);
  const rafToken = search.get('raf') || getRafToken();

  if (search.get('deep_link_value')) return search.get('deep_link_value');

  let deepLinkValue = cookieParams.reduce((acc, param) => {
    const value = search.get(param) || getCookie(param);
    if (!url.searchParams.has(param) && value) {
      setCookie(param, value);
    }
    return value ? `${acc + param}:${value}|` : acc;
  }, '').slice(0, -1);

  if (rafToken) {
    const rafUrl = `${window.location.origin}/${locale()}/raf/${rafToken}`;
    const divider = deepLinkValue ? '|' : '';
    deepLinkValue = `${deepLinkValue + divider}raf:${rafToken}|raf_url:${rafUrl}`;
  }

  return deepLinkValue;
};

const appendAppsflyerParams = (url) => {
  const currentUrl = new URL(url);

  if (!currentUrl.searchParams.has('deep_link_value')) {
    const deepLinkValue = buildDeepLinkValue(currentUrl);
    if (deepLinkValue) {
      currentUrl.searchParams.append('deep_link_value', deepLinkValue);
    }
  }

  window.location.href = currentUrl.toString();
};

const appendParams = (href) => {
  const pageUrl = new URL(window.location.href);
  const currentUrl = new URL(href.startsWith('http')
    ? href
    : (`${window.location.protocol}//${window.location.hostname}${href.startsWith('/') ? href : (`/${href}`)}`));

  pageUrl.searchParams.forEach((value, param) => {
    if (cookieParams.includes(param)) setCookie(param, value);
    currentUrl.searchParams.append(param, value);
  });

  window.location.href = currentUrl.toString();
};

export default (e, href, type) => {
  if (/onelink.me/.test(href)) {
    e.preventDefault();
    appendAppsflyerParams(href);
  } else if (
    type === 'landingPage' && !href.startsWith('#') && (!href.startsWith('http')
    || href.match(new RegExp(window.location.hostname)))
  ) {
    e.preventDefault();
    appendParams(href);
  }
};
