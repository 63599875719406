import { v as bvVar } from 'bv';

const {
  hasSportsbook = true,
  hasCasino = true,
  showTopLevelNavigation = true,
  homeProduct = 'sports',
  showQuickbetDefault = true,
  showBetslipAcceptAllPriceChanges = false,
  showBetslipAcceptAllLineChanges = false,
  kycLoginStrategies = '',
  generateHreflangs = true,
  showKycBanner = true,
  redirectToVerificationZone = false,
} = bvVar('siteConfigs').global || {};

export {
  hasSportsbook,
  hasCasino,
  showTopLevelNavigation,
  homeProduct,
  showQuickbetDefault,
  showBetslipAcceptAllPriceChanges,
  showBetslipAcceptAllLineChanges,
  kycLoginStrategies,
  generateHreflangs,
  showKycBanner,
  redirectToVerificationZone,
};
