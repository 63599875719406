import { loggedIn } from 'Helpers/session';
import { v as bvVar } from 'bv';

const DEFAULT_GAMES_SLUG = 'casino';

const { slug = DEFAULT_GAMES_SLUG } = bvVar('casinoLobbies').find(
  ({ tag }) => tag === 'default',
) || {};

export const isCasinoPath = () => {
  const path = window.location.pathname;
  return path.startsWith(`/${bvVar('locale')}/${slug}`);
};

export const seoEnabled = () => (!loggedIn() || isCasinoPath());
