const mediumWidth = 1024;
const largeWidth = 1280;

const width = () => window.innerWidth || document.documentElement.clientWidth;
const isSmall = () => width() < mediumWidth;
const isMediumOrGreater = () => width() >= mediumWidth;
const isLargeOrGreater = () => width() >= largeWidth;

export {
  isSmall,
  isMediumOrGreater,
  isLargeOrGreater,
};
